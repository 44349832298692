import React, { useState, useContext } from "react";

import { AzHeader, AzLogo, AzMainMenu, AzSecondLineMenu, AzLoginButton } from "@Development/ui-kit";
import {
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { ApplicationContext } from '@product-site-frontend/shared';
import ConsultationForm from "@product-site-frontend/shared/components/ConsultationForm";

import { NAV_LINKS, NAV_LINKS_EXTENDED_LINE } from "../constants/nav-links";
import * as styles from './appBar.module.css';

export default function AppBar() {
  const { setApplicationOpen } = useContext(ApplicationContext);

  const [showModalCallback, setShowModalCallback] = useState(false);

  function handleApplicationButtonClick() {
    setApplicationOpen(true);
  }


  const toggleShowModalCallback = () => {
    setShowModalCallback(!showModalCallback);
  }

  return (
    <AzHeader
      button={(<AzLoginButton
        urls={[
          {
            url: `${process.env.GATSBY_ACCOUNT_URL}/login`,
            text: 'Для клиентов'
          },
          {
            url: `${process.env.GATSBY_ACCOUNT_URL}/partnership/login`,
            text: 'Для партнеров'
          },
          {
            url: `${process.env.GATSBY_AGENT_URL}/login`,
            text: 'Для агентов'
          }
        ]}
      />)}
      className={styles.header}
      fixedWidth={true}
      handleApplicationCallBackClick={toggleShowModalCallback}
      href="/"
      logo={(<AzLogo svg={{ width: 125, height: 36 }} type="basic-new" />)}
      secondLine={(
        <AzSecondLineMenu
          className={styles.secondLineActive4}
          menu={
            NAV_LINKS_EXTENDED_LINE({
              gatsby: process.env.GATSBY_ROOT_URL,
              shop: process.env.GATSBY_SHOP_URL,
              documents: process.env.GATSBY_DOCUMENTS_PUBLIC_URL
            })
          }
        />
      )}
    >
      <AzMainMenu
        button={(<AzLoginButton
          small={true}
          urls={[
            {
              url: `${process.env.GATSBY_ACCOUNT_URL}/login`,
              text: 'Для клиентов'
            },
            {
              url: `${process.env.GATSBY_ACCOUNT_URL}/partnership/login`,
              text: 'Для партнеров'
            },
            {
              url: `${process.env.GATSBY_AGENT_URL}/login`,
              text: 'Для агентов'
            }
          ]}
        />)}
        className={styles.mobileLineActive4}
        handleApplicationButtonClick={handleApplicationButtonClick}
        handleApplicationCallBackClick={toggleShowModalCallback}
        href="/"
        logo={(<AzLogo svg={{ width: 125, height: 36 }} type="basic-new" />)}
        menu={
          NAV_LINKS({
            gatsby: process.env.GATSBY_ROOT_URL,
            shop: process.env.GATSBY_SHOP_URL
          })
        }
        mobileMenu={
          NAV_LINKS_EXTENDED_LINE({
            gatsby: process.env.GATSBY_ROOT_URL,
            shop: process.env.GATSBY_SHOP_URL,
            documents: process.env.GATSBY_DOCUMENTS_PUBLIC_URL
          })
        }
      />
      {showModalCallback && (
        <Dialog onClose={toggleShowModalCallback} open={true}>
          <DialogTitle
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography sx={{ fontSize: { xs: '22px', sm: '26px', lg: '26px', }, fontWeight: 700 }}>Заказать звонок</Typography>
            <IconButton aria-label="close" onClick={toggleShowModalCallback}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <ConsultationForm />
          </DialogContent>
        </Dialog>
      )}
    </AzHeader>
  );
}
